<template>
  <main class="main page QUizcomplete">
    <div class="quiz quiz-complete">
      <div class="quiz-content">
        <!-- <div class="quiz__project-name">{{ targetSurvey ? targetSurvey.projectName : 'My Room' }}</div> -->
        <h1 class="congratulations__heading">Congratulations!</h1>
        <!-- <div class="quiz__progress"></div> -->
        <h2 v-if="ossMode" class="heading__greensubheading">
          On-Site Service it is.
        </h2>
        <h2 v-else class="congratulations__subheading">
          Your furniture selections are in the works!
        </h2>
        <form class="form">
          <div class="grid__inner">
            <div class="form__content">
              <div class="form__item">
                <div class="congratulations__text">
                <HeadingTt v-if="ossMode">
                  We look forward to taking some quick photos and measurements of your space! As a precaution, we’ll be fully masked up and make sure to maintain social distance. If a conflict should arise on your end, please let us know ASAP and we’d be happy to reschedule.
                </HeadingTt>
                <HeadingTt v-else>
                  <p>Lyndsey F. here, your personal, toTree specialist throughout the entire process.
                  <strong>Within 72 hours,</strong> I'll be emailing you two Style Boards with your personalized
                  furniture options, based on your specific needs and preferences.</p>
                  <p>If you have any questions in the meantime - or when you receive your boards - please don't
                  hesitate to reach out to me at Lyndsey@totree.co or (919) 923-8795. (And no 'bot' here:
                  I'm a flesh-and-blood furniture expert!)</p>
                </HeadingTt>
                </div>
                <div class="completion__image">
                <!-- <img src="@/assets/images/done.png"> -->
                 <CloudinaryImage public-id="ToTree/webapp/static/quiz-completed-1"/>
                 <CloudinaryImage public-id="ToTree/webapp/static/quiz-completed-2"/>
                 <CloudinaryImage public-id="ToTree/webapp/static/quiz-completed-3"/>
                </div>
                <div class="congratulations__bottom__text">
                  <span>Now let's get started on your next room!</span>
                </div>
                <div class="custom-actions">
                <template v-if="previewSelectionsLoaded===false">
                  <router-link to="/projects">
                      <button class="btn-Border">
                        No thanks, I'm good
                      </button>
                  </router-link>
                  <router-link to="/quiz">
                    <button class="customGreenBTN">
                      I'm ready, let's do it
                    </button>
                  </router-link>
                  </template>
                  <router-link v-if="previewSelectionsLoaded" :to="`/results/${targetSurvey?.id}`">
                    <button class="customGreenBTN">
                      See your selections!
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.congratulations__heading {
  font-family: 'League Spartan';
  font-size: 35px;
  font-weight: 700;
  color: #004021;
}

.congratulations__subheading {
  font-family: 'League Spartan';
  font-size: 20px;
  font-weight: 600;
  color: #004021;
  margin-bottom: 30px;
}
.congratulations__text {
  text-align: left !important;
  font-style: italic !important;
}

.completion__image {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 20px;
  padding: 0 250px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(1,1fr);
    padding: 20px;
  }
}

.completion__image > * {
  justify-self: center;
  max-width: 100%;
  height: auto;
}

.congratulations__bottom__text{
  font-family: 'League Spartan';
  font-size: 30px;
  font-weight: 600;
  color: #004021;
  margin-bottom: 30px;
  margin-top: 50px;
}
.quiz-complete {
  text-align: center;
  .form__heading {
    max-width: 580px;
    margin: 0 auto;
  }
  .form__content {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  img {
    max-height: 300px;
  }
  .custom-actions{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;
      gap: 3rem;
    }
  }
  .form__heading__content .oss-top-margin{
    margin-top:10px !important;
    display: inline-block;
  }
}
</style>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { httpsCallable } from 'firebase/functions'
import CloudinaryImage from '@/components/CloudinaryImage.vue'

export default {
  data () {
    return {
      baseUrl: window.location.origin + '/projects',
      previewSelectionsLoaded: null
    }
  },
  async mounted () {
    await this.getTargetSurvey({ id: this.targetSurvey.id, setState: true })

    await this.updateFirestoreCurrentQuiz({
      inProgress: false,
      isPending: true
    })

    if (this.$route.query.previewSecret === 'gkL0TTAE2R355VxeRnZ886p0hcWnS6AI') {
      await this.copyPredefinedSelections()
      this.previewSelectionsLoaded = true
    } else {
      this.previewSelectionsLoaded = false
    }
  },
  computed: {
    ...mapGetters(['targetSurvey', 'userProfile']),
    ...mapState(['fbFunctions']),
    ossMode () {
      // return true
      return this.targetSurvey?.responses?.specs_options === 'on-site-service' || false
    }
  },
  components: {
    CloudinaryImage
  },
  methods: {
    ...mapActions(['getTargetSurvey', 'updateFirestoreCurrentQuiz']),

    async copyPredefinedSelections () {
      await httpsCallable(this.fbFunctions, 'copyPredefinedSelectionsForUser')({ id: this.targetSurvey?.id })
    }
  }
}
</script>
